<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Change Password" />
  <!-- Breadcroumbs end -->

  <!-- Change Password start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-9">
          <div class="my-account-page-title">
            <h3>Change Your Password</h3>
          </div>
          <div class="order-form change-pass-form">
            <form action="#">
              <div class="form-group">
                <div class="row">
                  <label class="col-12 col-sm-6 col-md-4 col-lg-4 text-right"
                    >Old Password:</label
                  >
                  <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                    <input
                      type="password"
                      class="form-control"
                      name="oldPass"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label class="col-12 col-sm-6 col-md-4 col-lg-4 text-right"
                    >New Password:</label
                  >
                  <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                    <input
                      type="password"
                      class="form-control"
                      name="newPass"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label class="col-12 col-sm-6 col-md-4 col-lg-4 text-right"
                    >Confirm New Password:</label
                  >
                  <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                    <input
                      type="password"
                      class="form-control"
                      name="cNewPass"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 offset-sm-6 col-md-8 offset-md-4">
                  <button type="button" class="btn btn-submit">
                    Change Password
                  </button>
                </div>
                <div class="col-12 col-sm-6 offset-sm-6 col-md-8 offset-md-4">
                  <button type="button" class="btn btn-submit" @click="resetPassword">
                    Reset with Email
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ProfileNavigation />
      </div>
    </div>
  </div>
  <!-- Change Password End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src
import { auth } from "@/includes/firebase";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import ProfileNavigation from "@/components/ProfileNavigation.vue";

export default {
  name: "ChangePassword",
  components: {
    Breadcrumbs,
    Newsletter,
    ProfileNavigation,
  },
  methods: {
    signout() {
      auth
        .signOut()
        .then(() => {
          this.$router.replace("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetPassword() {
      // user email is from auth
      auth.sendPasswordResetEmail(auth.currentUser.email).then(() => {
        console.log("Email sent");
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
